"use client";
import { IoLocationOutline } from "react-icons/io5";
import Link from 'next/link';
import { MdOutlineEmail, } from "react-icons/md";
import { useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaPinterest, FaSkype, FaYoutube } from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import Image from 'next/image';
import { FiTool } from "react-icons/fi";
import { Useapi } from '@/helpers/apiContext'
import { motion } from 'framer-motion'
import { MdWifiCalling3 } from "react-icons/md";
// import { Useapi } from "@/helpers/apiContext";
const Header = () => {
  const { toggleDropdown } = Useapi();
  const { basic_details } = Useapi()
  // console.log("basic_details",basic_details);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const handleToggle = (menu: string) => {
    setOpenDropdown(openDropdown === menu ? null : menu);
  };
  return (
    <>
      <div className='bg-purple text-white' onMouseLeave={() => handleToggle('')} onMouseEnter={() => {
        toggleDropdown('')
      }}>
        <div className='w-full px-4 xl:w-[95%] 2xl:w-[80%] mx-auto'>
          <div className='flex justify-between  2xl:justify-between items-center '>
            <div className='block 2xl:block my-3'>
              <div className='flex gap-2 sm:gap-2 text-[19px]'>
                <Link target='_blank' aria-label="Go to  w3era facebook page" title="Go to Home w3era facebook page"
                  href={basic_details?.basic_details[0]?.facebook_url || ''}>
                  <FaFacebook /></Link>
                <Link target='_blank' aria-label="Go to w3era instagram Page " title="Go to w3era instagram Page" href={basic_details?.basic_details[0].instagram_url || ''}><FaInstagram /></Link>
                <Link target='_blank' aria-label="Go to w3era Linkedin Page" title="Go to w3era Linkedin Page" href={basic_details?.basic_details[0].linkedin_url || ''}><FaLinkedinIn /></Link>
                <Link target='_blank' aria-label="Go to w3era Twitter Page" title="Go to w3era Twitter Page" href={basic_details?.basic_details[0].twitter_url || ''}><BsTwitterX /></Link>
                <Link target='_blank' aria-label="Go to w3era Skype Page" title="Go to w3era Skype Page" href={basic_details?.basic_details[0].skype_url || ''}><FaSkype /></Link>
                {basic_details?.basic_details[0]?.email && (
                  <Link
                    target="_blank"
                    aria-label="Go to w3era Email Page"
                    title="Go to w3era Email Page"
                    href={`mailto:${basic_details.basic_details[0].email}`}>
                    <MdOutlineEmail />
                  </Link>
                )}                <Link target='_blank' aria-label="Go to w3era Youtube Page" title="Go to w3era Youtube Page" href={basic_details?.basic_details[0].youtube_url || ''}><FaYoutube /></Link>
                <Link target='_blank' aria-label="Go to w3era Pinterest Page" title="Go to w3era Pinterest Page" href={basic_details?.basic_details[0].pinterest_url || ''}><FaPinterest /></Link>
                <Link target='_blank' aria-label="Go to w3era Gmb Page" title="Go to w3era Gmb Page" href={basic_details?.basic_details[0].gmb_url || ''}><IoLocationOutline /></Link>
              </div>
            </div>
            <div className='flex text-white gap-4 py-3 items-center text-[14px] xl:text-[16px]'>
              <div className='items-center 2xl:font-semibold hidden lg:flex gap-5 2xl:gap-5'>
                {/* About Us Dropdown */}
                <div className='relative'>
                  <div
                    onMouseOver={() => handleToggle("about-us")}
                    className='flex items-center gap-1 cursor-pointer'
                  >
                    About Us
                    {openDropdown === "about-us" ? <Image
                      src={'/images/down-arrow-header.svg'}
                      width={14}
                      height={11}
                      alt={'w3era digital marketing company'}
                      className="cursor-pointer mt-1"
                    /> : <Image
                      src={'/images/up-arrow-header.svg'}
                      width={14}
                      height={11}
                      alt={'w3era digital marketing company'}
                      className="cursor-pointer mt-1"
                    />}
                  </div>
                  {openDropdown === "about-us" && (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={{
                        hidden: { opacity: 0, y: -10, scale: 0.95 },
                        visible: { opacity: 1, y: 0, scale: 1 }
                      }}
                      transition={{ duration: 0.3, ease: [0.25, 0.8, 0.5, 1] }} // Smooth easing
                      className="absolute w-[200px] text-homeblack border-lightpink border-[2px] text-left z-50 top-10 flex flex-col bg-white rounded-md"
                      onMouseLeave={() => handleToggle('')}
                    >
                      <Link target='_blank' href={'/about-us/awards/'}><div className=' hover:bg-pink px-5 hover:text-white py-4   border-slate-300'>Our Awards</div></Link>
                      <Link target='_blank' href={'/about-us'}><div className=' hover:bg-pink px-5 hover:text-white py-4   border-slate-300'>Who we are</div></Link>
                      <Link target='_blank' href={'/life-at-w3era'}><div className=' hover:bg-pink px-5 hover:text-white py-4   border-slate-300'>Life at w3era</div></Link>
                      <Link target='_blank' href={'/faq'}><div className=' hover:bg-pink hover:text-white  px-5  py-4 border-slate-300'>
                        Know More</div></Link>
                      {/* <Link target='_blank' href={'/seo/company/'}><div className=' hover:bg-pink hover:text-white  px-5  py-4 border-slate-300'>
                      SEO Company</div></Link> */}
                    </motion.div>
                  )}
                </div>
                <div className='relative'>
                  <div
                    onMouseOver={() => handleToggle("our-work")}
                    className='flex items-center text-white gap-1 cursor-pointer'
                  >
                    Our Work
                    {
                      openDropdown === "our-work" ? <Image
                        src={'/images/down-arrow-header.svg'}
                        width={14}
                        height={11}
                        alt={'w3era digital marketing company'}
                        className="cursor-pointer mt-1"
                      /> : <Image
                        src={'/images/up-arrow-header.svg'}
                        width={14}
                        height={11}
                        alt={'w3era digital marketing company'}
                        className="cursor-pointer mt-1"
                      />
                    }
                  </div>
                  {
                    openDropdown === "our-work" && (
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={{
                          hidden: { opacity: 0, y: -10, scale: 0.95 },
                          visible: { opacity: 1, y: 0, scale: 1 }
                        }}
                        transition={{ duration: 0.3, ease: [0.25, 0.8, 0.5, 1] }} // Smooth easing
                        className="absolute w-[200px] text-homeblack border-lightpink border-[2px] text-left z-50 top-10 flex flex-col bg-white rounded-md"
                        onMouseLeave={() => handleToggle('')}
                      >
                        <Link target='_blank' href={'/testimonials'}><div className='hover:text-white py-4 px-5 hover:bg-pink border-slate-300'>Testimonials</div></Link>
                        <Link target='_blank' href={'/case-studies'}><div className='hover:text-white py-4 px-5 hover:bg-pink border-slate-300'>Case Study</div></Link>
                        <Link target='_blank' href={'/our-client-list'}><div className='hover:text-white py-4 px-5 hover:bg-pink '>Our Clients</div></Link>
                      </motion.div>
                    )
                  }
                </div>
                <Link target='_blank' href={'/blog'} aria-label="Go to Blog Page" title="Go to Blog Page">
                  <div>Blog</div>
                </Link>
                <Link target='_blank' href={'/web-stories'} aria-label="Go to Web Stories Page" title="Go to Web Stories Page">
                  <div>Web Story</div>
                </Link>
                <div className=''>
                  <Link target='_blank' href={'/career'} aria-label="Go to Career Page" title="Go to Career Page">
                    <div>Career</div>
                  </Link>
                </div>
                <Link target='_blank' href='/contact-us' aria-label="Go to contact Page" title="Go to Home Page">
                  <div>Contact Us</div>
                </Link>
              </div>
              <button className="mr-5 hidden sm:flex  bg-pinkdarker  items-center justify-center px-2 w-[40px] h-[40px] py-[8px] 2xl:py-3 text-white bg-pink hover:bg-pinkdarker rounded-full transition duration-300">
                <span
                  className="font-semibold text-[24px]"
                  style={{
                    animation: "shake 0.5s ease-in-out infinite",
                    display: "inline-block",
                  }}
                >
                  <Link
                    href={`tel:${basic_details?.basic_details[0]
                      ? basic_details?.basic_details[0]?.country === 'US'
                        ? basic_details?.basic_details[0]?.us_number || ''
                        : basic_details?.basic_details[0]?.phonenumber || ''
                      : ''}`}
                    aria-label="Talk To our Team"
                    title="Talk To our Team"
                  >
                    <MdWifiCalling3 />
                  </Link>

                </span>
              </button>
              <div
                className="relative hidden  sm:flex w-fit"
              >
                <button
                  className="relative  bg-pinkdarker flex items-center justify-center px-1 sm:px-4 md:px-6 text-white rounded-lg py-2 group bg-pink-500 transition duration-300 overflow-hidden"
                >
                  <Link href={'https://app.w3era.com/'} target="_blank"
                    aria-label="Explore Seo tools" title="Explore Seo tools"
                  >
                    <span className="redirect-to-app_w3era-laptop">
                      Explore New SEO Tools
                    </span>
                  </Link>
                  <Link href={'https://app.w3era.com/'} target="_blank"

                    aria-label="Explore Seo tools" title="Explore Seo tools"
                  >
                    <span className="redirect-to-app_w3era-mobile">
                      New SEO Tools
                    </ span>
                  </Link>
                  <FiTool className="text-[20px] hidden sm:block opacity-0 group-hover:opacity-100 transition duration-300 group-hover:translate-x-2" />
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
