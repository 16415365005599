'use client'
import React from 'react'
import { useState } from "react"
import { Phone, Mail} from "lucide-react"
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { motion } from 'framer-motion';
import { IoLogoWhatsapp } from "react-icons/io";
import { FaSkype } from "react-icons/fa";
import Link from 'next/link';


const Footercontact = ({ basic_details }: any) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = () => setIsOpen(!isOpen)
    const buttonVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: (i:any) => ({
          opacity: 1,
          y: 0,
          transition: { delay: i * 2, duration: 0.5 },
        }),
      };
      const buttons = [
        { icon: <Phone className='md:text-[24px] text-[14px]' />, bg: 'flex justify-center itme-center bg-green-500 hover:bg-green-600', 
          link:`tel:${basic_details?.basic_details[0].phonenumber} ` },
        { icon: <Mail className='md:text-[24px] text-[20px]' />, bg: 'flex justify-center itme-center bg-blue hover:bg-blue-600' ,link:`mailto:${basic_details?.basic_details[0].email}` },
        { icon: <IoLogoWhatsapp className='md:text-[24px] text-[22px]' />, bg: 'flex justify-center itme-center bg-green-500 ' ,link:'https://api.whatsapp.com/send?phone=917073197281' },
        { icon: <FaSkype  className='md:text-[24px] text-[22px]' />, bg: 'flex justify-center itme-center bg-blue ',link:`${basic_details?.basic_details[0].skype_url}`  },
      ];
      
  return (
    <div>
        <div className="fixed z-40 hidden 2xl:inline  -right-28 bottom-48 border-2 border-slate-200 rounded-md hover:border-none rotate-[270deg] h-[67px]">
                        <div className="group grid grid-cols-4 w-[267px] relative gap-0 hover:gap-2 duration-500 shadow-sm">
                            <div className="absolute text-blue text-[18px]  text-blue-500 font-semibold z-10 group-hover:hidden duration-200 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 whitespace-nowrap">
                                Talk To us
                            </div>
                            <a href={basic_details?.basic_details[0].skype_url ||''} target="_blank"  aria-label="contact us" title="contact us">
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="white"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    className="group-hover:rounded-lg bg-white group-hover:rotate-90 group-hover:opacity-100 p-4
                                    bg-blue-100 border-blue-500  hover:border-2 group-hover:bg-blue backdrop-blur-md group-hover:shadow-xl
                                    flex justify-center items-center w-full h-full text-blue-500 hover:text-white duration-200"
                                >
                                    <path d="M12.069 18.874c-4.023 0-5.82-1.979-5.82-3.464 0-.765.561-1.296 1.333-1.296 1.723 0 1.273 2.477 4.487 2.477 1.641 0 2.55-.895 2.55-1.811 0-.551-.269-1.16-1.354-1.429l-3.576-.895c-2.88-.724-3.403-2.286-3.403-3.751 0-3.047 2.861-4.191 5.549-4.191 2.471 0 5.393 1.373 5.393 3.199 0 .784-.688 1.24-1.453 1.24-1.469 0-1.198-2.037-4.164-2.037-1.469 0-2.292.664-2.292 1.617s1.153 1.258 2.157 1.487l2.637.587c2.891.649 3.624 2.346 3.624 3.944 0 2.476-1.902 4.324-5.722 4.324m11.084-4.882l-.029.135-.044-.24c.015.045.044.074.059.12.12-.675.181-1.363.181-2.052 0-1.529-.301-3.012-.898-4.42-.569-1.348-1.395-2.562-2.427-3.596-1.049-1.033-2.247-1.856-3.595-2.426-1.318-.631-2.801-.93-4.328-.93-.72 0-1.444.07-2.143.204l.119.06-.239-.033.119-.025C8.91.274 7.829 0 6.731 0c-1.789 0-3.47.698-4.736 1.967C.729 3.235.032 4.923.032 6.716c0 1.143.292 2.265.844 3.258l.02-.124.041.239-.06-.115c-.114.645-.172 1.299-.172 1.955 0 1.53.3 3.017.884 4.416.568 1.362 1.378 2.576 2.427 3.609 1.034 1.05 2.247 1.857 3.595 2.442 1.394.6 2.877.898 4.404.898.659 0 1.334-.06 1.977-.179l-.119-.062.24.046-.135.03c1.002.569 2.126.871 3.294.871 1.783 0 3.459-.69 4.733-1.963 1.259-1.259 1.962-2.951 1.962-4.749 0-1.138-.299-2.262-.853-3.266" />
                                </svg>
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=917073197281" target="_blank" aria-label="contact us" title="contact us">
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="white"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    className="group-hover:rounded-lg bg-white group-hover:opacity-100 group-hover:rotate-90 p-4 bg-blue-100 border-blue-500
                                    hover:border-2 group-hover:bg-green-600 backdrop-blur-md group-hover:shadow-xl flex justify-center items-center w-full h-full text-green-600 hover:text-white duration-200"
                                >
                                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                                </svg>
                            </a>
                            {

basic_details?.basic_details[0].email &&
                            <a href={`mailto:${basic_details?.basic_details[0].email}`} aria-label="contact us" title="contact us">
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    className="group-hover:rounded-lg bg-white group-hover:opacity-100 group-hover:rotate-90 p-4 bg-blue-100 border-blue-500 hover:border-2 group-hover:bg-blue backdrop-blur-md group-hover:shadow-xl flex justify-center items-center w-full h-full text-black hover:text-white duration-200"
                                >
                                    <path
                                        d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinejoin="round"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M22 6l-10 7L2 6"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinejoin="round"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </a>
}
{
  basic_details?.basic_details[0].phonenumber &&

                            <a href={`tel:${basic_details?.basic_details[0].phonenumber}`} aria-label="contact us" title="contact us">
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="white"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    className="group-hover:rounded-lg  group-hover:opacity-100 p-4 group-hover:rotate-90  bg-white border-blue-500 hover:border-2 group-hover:bg-green-500 backdrop-blur-md group-hover:shadow-xl flex justify-center items-center w-full h-full text-green-500 hover:text-white duration-200"
                                >
                                    <path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z" />
                                </svg>
                            </a>
}
                        </div>
                    </div>
                
                  <div className="fixed 2xl:hidden bottom-24 right-4">
      <div className="relative">
        {isOpen && (
          <div className="absolute bottom-16 right-0 flex flex-col space-y-4">
            {buttons.map((btn, index) => (
              <motion.button
                key={index}
                className={`${btn.bg} text-white rounded-full  p-3 shadow-lg transition-all duration-200 transform hover:scale-110`}
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * .1, duration: 0.2 }}
              >
                {btn.link &&
                  <Link  aria-label="Contact us" title="Contact us" href={btn.link}>
                {btn.icon}
                </Link>}
              </motion.button>
            ))}
          </div>
        )}
        <button
          onClick={toggleOpen}
          className="bg-primary hover:bg-primary-dark text-white rounded-full p-4 shadow-lg transition-all duration-200 transform hover:scale-110"
          aria-label="connect To our Team" title="connect To our Team"
        >
          <TfiHeadphoneAlt size={24} />
        </button>
      </div>
    </div>

    </div>
  )
}

export default Footercontact